import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route,  Navigate,   Outlet } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { history } from './helpers/history';
import { alertActions } from './actions/alert.actions';
import { PrivateRoute } from './components/PrivateRoute';
import LoginPage from './pages/LoginPage';
import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

export default function App() {
  const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, [dispatch]);


  return (

      
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ScrollToTop />
          <StyledChart />
          {alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
                  <Router  history={history} >
                         <Routes>
                            <Route exact path="/" component={ DashboardAppPage }/>
                            <Route path="/login" component={LoginPage} />
                            <Navigate from="*" to="/" />
                        </Routes>
                  </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
