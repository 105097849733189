import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import axios from 'axios';
import config from '../../../config';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({ 
    email: '',
    password:'',
  });

  const [showPassword, setShowPassword] = useState(false);
  const API_HOST = config.API_URL;
  const handlePasswordChange = (e)=>{
    data.password=e.target.value;
  };

  const handleEmailChange = (e)=>{
    data.email=e.target.value;
  };

  const handleClick = () => {
    axios.post(API_HOST+'/api/Users/login', data).then(res => {
      if(res.status===200){
        localStorage.setItem('token', res.data['jwtToken']);
        localStorage.setItem('user', JSON.stringify(res.data['user']));
      }
      setLoading(false);
      navigate('/dashboard', { replace: true });
    }).catch(err => {
      setLoading(false);
      setIsError(true);
    });
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email"   onChange={handleEmailChange} label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
