// component
import SvgColor from '../../../components/svg-color';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Khách Hàng',
  //   path: '/dashboard/customer-list',
  //   icon: icon('ic_user')
  // },
  {
    title: 'Tài Khoản Yahoo',
    path: '/dashboard/yahoo-user',
    icon: icon('ic_user'),
  },
  {
    title: 'Đơn Hàng Yahoo',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  // INVOICE
  {
    title: 'Hóa Đơn',
    path: '/dashboard/invoice',
    icon:  ICONS.invoice,
  },
  // {
  //   title: 'Hóa Đơn',
  //   path: '/dashboard/invoice',
  //   icon: <svg data-testid="ReceiptLongIcon"></svg>
  // },
  {
    title: 'Giao dịch',
    path: '/dashboard/transaction',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
