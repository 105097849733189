import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import TransactionPage from './pages/TransactionPage';
import YahooUserPage from './pages/YahooUserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import InvoicePage from './pages/InvoicePage';
import CustomerList from './pages/CustomerList';
import CustomerCreate from './pages/CustomerCreate';
// ----------------------------------------------------------------------

export default function Router() {

  

  function RequireAuth({ children, redirectTo }) {
    let isAuthenticated = localStorage.getItem("user")!==null && localStorage.getItem("user")!==undefined;
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
  }

  const routes = useRoutes([
    {
      path: '/dashboard',
      element:   <RequireAuth  children= {<DashboardLayout/>} redirectTo="/login"/>,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element:   <DashboardAppPage/>},
        { path: 'customer-list', element: <CustomerList/> },
        { path: 'customer-new', element: <CustomerCreate/> },
        { path: 'yahoo-user', element: <YahooUserPage/> },
        { path: 'products', element:<ProductsPage />  },
        { path: 'transaction',element:<TransactionPage />},
        { path: 'invoice', element: <InvoicePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
