import ReactDOM from 'react-dom/client';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

import { store } from './helpers';
import { configureFakeBackend } from './helpers';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

// setup fake backend
configureFakeBackend();

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
