import { Helmet } from 'react-helmet-async';

import React, { ChangeEventHandler, useMemo, useCallback , useState, useEffect } from 'react'
import axios, * as others from 'axios'
import config from '../config';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Iconify from '../components/iconify';
import Pagination from '@mui/material/Pagination';
import OrderAnalytic from '../sections/@dashboard/order/OrderAnalytic';
import sumBy from 'lodash/sumBy';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

// @mui
import { 
  Container, 
  Stack, 
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Button,
  TextField,
  Tabs,
  Tab,
  Divider,
  Box,
  Card,
  Table,
  Switch,
  Tooltip,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';

// redux
import { useDispatch, useSelector } from '../redux/store';
import { getOrders } from '../redux/slices/product';
// route
import { PATH_DASHBOARD } from '../routes/paths';
//hooks
import useSettings from '../hooks/useSettings';

import useTable, { getComparator, emptyRows } from '../hooks/useTable';
import { paramCase } from 'change-case';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import Scrollbar from '../components/scrollbar';

import { fShortenNumber } from '../utils/formatNumber';
// _mock_
import { _invoices } from '../_mock/_invoice';

import {
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedActions,
} from '../components/table';


// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// const { productId, title, imageUrl, url, cover, createdAt, inventoryType, productPrice } = row;
const TABLE_HEAD = [
  { id: 'title', label: 'Sản phẩm', align: 'left' },
  { id: 'createdAt', label: 'Ngày Mua', align: 'left' },
  { id: 'inventoryType', label: 'Trạng Thái', align: 'center', width: 180 },
  { id: 'productPrice', label: 'Giá', align: 'right' },
  { id: '' },
];

export default function ProductsPage() {
  const theme = useTheme();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [filterName, setFilterName] = useState('');


  const [openFilter, setOpenFilter] = useState(false);
  const API_HOST = config.API_URL;
    const [orderList, setOrderList] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});

    const [totalCount, setTotalCount] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [totalUnpaid, setTotalUnpaid] = useState(0);

    const [totalCancel, setTotalCancel] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalShippingFee, setTotalShippingFee] = useState(0);
    const[accounts, setAccounts] = useState([]);
    const[pageItems, setPageItems] = useState([]);
    const[fromDate, setFromDate] = useState(new Date());
    const[toDate, setToDate] = useState(new Date());
    const [purchaseDate, setPurchaseDate] = useState( selectedOrder.purchaseDate!=null? new Date(selectedOrder.purchaseDate): new Date());
    const [sellerShippingDate, setSellerShippingDate] = useState( selectedOrder.sellerShippingDate!=null? new Date(selectedOrder.sellerShippingDate): new Date());
    const [isSync, setIsSync] = useState(false);
    const [filter, setFilter] = useState({ 
    buyAccount: '',
    searchText:'',
    fromDate: new Date(),
    toDate:new Date(),
    pageSize:8,
    pageIndex: 0});

  const [tableData, setTableData] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

    const handleFilterName = (filterName) => {
      setFilterName(filterName);
      setPage(0);
    };
  
    const handleDeleteRow = (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setSelected([]);
      setTableData(deleteRow);
    };
  
    const handleDeleteRows = (selected) => {
      const deleteRows = tableData.filter((row) => !selected.includes(row.id));
      setSelected([]);
      setTableData(deleteRows);
    };
  
    const handleEditRow = (id) => {
      navigate(PATH_DASHBOARD.eCommerce.edit(paramCase(id)));
    };
  
  
// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  return tableData;
}


    const denseHeight = dense ? 60 : 80;
  
    const isNotFound = (!dataFiltered.length && !!filterName) || (!dataFiltered.length);
    const fetchOrders = ()=>{
      const url = API_HOST+"/api/orders/fetchOrder";
      setShowProgress(true);
      axios({
              method: 'post',
              url: url,
              headers: {}, 
              data: filter
          }).then(function (response) {
              setShowProgress(false);
              searchOrder();
          }).catch((error) => {
              console.error("Error", error);
              setShowProgress(false);
          });
    }

    const searchOrder = ()=>{
      const url = API_HOST+"/api/orders/search";
      axios({
              method: 'post',
              url: url,
              headers: {}, 
              data: filter
          }).then(function (response) {

              setOrderList(response.data.orders);
              setFilter(response.data.filter);
              setTotalCount(response.data.totalCount);
              setTotalAmount(response.data.totalAmount);
              setTotalShippingFee(response.data.totalShippingFee);
              setTotalPaid(response.data.totalPaid);
              setTotalUnpaid(response.data.totalUnpaid);
              setTotalCancel(response.data.totalCancel);

              var pages = [];
              for(var i=0; i<response.data.totalCount/filter.pageSize; i++){
                      if(i> 10){
                        pages.push("...");
                        break;
  
                      }else{
                        pages.push(i+1);
                      }
              }
              setPageItems(pages);
              setTableData(response.data.orders);
          }).catch((error) => {
              console.error("Error", error);
          });
    }
  
    const getYahooAccount = ()=>{
      const account_url = API_HOST+"/api/YahooAccount";
      axios
        .get(account_url)
        .then((res) => {
          setAccounts(res.data);
          console.debug(res.data);
        })
        .catch((error) => {
          console.error("Error", error);
      });
    }
  
    
    
      useEffect(() => {
           getYahooAccount();
           setFilter(filter);
           searchOrder();
      }, [])
      
      // useEffect(() => {
      //   dispatch(getOrders(filter));
      // }, [dispatch]);
    
      // useEffect(() => {
      //   if (orderList.length) {
      //     setTableData(orderList);
      //   }
      // }, [orderList]);

    const syncToErp = () => {
      const url = API_HOST+"/api/orders/SyncToErp";
      setIsSync(true);
      axios({
              method: 'post',
              url: url,
              headers: {}, 
              data: filter
          }).then(function (response) {
            setIsSync(false);
          }).catch((error) => {
            setIsSync(false);
              console.error("Error", error);
          });
      
    };
  
    const updateOrders = () => {
      const url = API_HOST+"/api/orders/SyncToErp";
      setIsSync(true);
      axios({
              method: 'post',
              url: url,
              headers: {}, 
              data: filter
          }).then(function (response) {
            setIsSync(false);
          }).catch((error) => {
            setIsSync(false);
              console.error("Error", error);
          });
      
    };

    const accountChanged=(e)=>{
      filter.buyAccount=e.target.value;
      filter.pageIndex=0;
      setFilter(filter);
      searchOrder();
      // console.log(value);
    }
  
    const searchChanged=(e)=>{
      const delayDebounceFn = setTimeout(() => {
        filter.searchText=e.target.value;
        filter.pageIndex=0;
        setFilter(filter);
        searchOrder();
      }, 3000)
  
      return () => clearTimeout(delayDebounceFn)
    }

    
    const onFromDateChange = (e)=>{
      filter.fromDate=e;
      setFromDate(e);
      filter.pageIndex=0;
      setFilter(filter);
      searchOrder();
    }
  
    const onToDateChange = (e)=>{
      filter.toDate=e;
      setToDate(e);
      filter.pageIndex=0;
      setFilter(filter);
      searchOrder();
    }
  
    const nextPage = ()=>{
      if(filter.pageIndex<totalCount/filter.pageSize){
          filter.pageIndex=filter.pageIndex+1;
          searchOrder();
      }
    }
  
    const prevPage = ()=>{
      if(filter.pageIndex>0){
          filter.pageIndex=filter.pageIndex-1;
          searchOrder();
      }
    }
  
    const goToPage = (index)=>{
          filter.pageIndex=index;
          searchOrder();
    }

    const handleChange = (event, value) => {
          filter.pageIndex=value-1;
          searchOrder();
    };
    
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };


  const onCancelCompleted = ()=>{
    searchOrder();
  }

  const getLengthByStatus = (status) => tableData.filter((item) => item.status === status).length;

  const getTotalPriceByStatus = (status) =>
    sumBy(
      tableData.filter((item) => item.status === status),
      'totalPrice'
    );

  const getPercentByStatus = (status) => (getLengthByStatus(status) / tableData.length) * 100;


  return (
    <>
      <Helmet>
        <title> Đơn Hàng Yahoo </title>
      </Helmet>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
      <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <OrderAnalytic
                title="Tổng Đơn"
                total={totalCount}
                percent={100}
                price={totalAmount}
                icon="ic:round-receipt"
                color={theme.palette.info.main}
              />
              <OrderAnalytic
                title="Thanh Toán"
                total={totalPaid}
                percent={totalPaid*100/totalCount}
                price={getTotalPriceByStatus('paid')}
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
              />
              <OrderAnalytic
                title="Chưa Thanh Toán"
                total={totalUnpaid}
                percent={totalUnpaid*100/totalCount}
                price={getTotalPriceByStatus('unpaid')}
                icon="eva:clock-fill"
                color={theme.palette.warning.main}
              />
              <OrderAnalytic
                title="Đơn Hủy"
                total={totalCancel}
                percent={totalCancel*100/totalCount}
                price={getTotalPriceByStatus('overdue')}
                icon="eva:bell-fill"
                color={theme.palette.error.main}
              />
              {/* <OrderAnalytic
                title="Draft"
                total={getLengthByStatus('draft')}
                percent={getPercentByStatus('draft')}
                price={getTotalPriceByStatus('draft')}
                icon="eva:file-fill"
                color={theme.palette.text.secondary}
              /> */}
            </Stack>
          </Scrollbar>
        </Card>

        {/* <Card> */}
          {/* <ProductTableToolbar filterName={filterName} onFilterName={handleFilterName} /> */}
          <Grid   sx={{ mb: 3}}  container rowSpacing={1} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
         <Grid item xs={2} sm={3} md={3} >
                <FormControl fullWidth size="sm" >
                <TextField onChange={searchChanged} id="outlined-basic" label="Từ Khóa" variant="outlined" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={3} >
                <FormControl fullWidth size="sm" >
                <InputLabel id="demo-select-small-label">Tài Khoản Mua</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  // value={age}
                  // label="Age"
                  onChange={accountChanged}
                >
                          {accounts.map((item, index) => (
                                <MenuItem  key={item.id} value={item.accountName}>{item.accountName}</MenuItem>
                          ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={3} >
                <DatePicker       size="sm" label="Từ Ngày" 
                value={dayjs(fromDate)}
                onChange={(date) => onFromDateChange(date)}
                />            
            </Grid>
            <Grid item xs={2} sm={3} md={3} >
                <DatePicker
                  label="Đến Ngày"
                  value={dayjs(toDate)}
                  size="sm"
                  onChange={(date) => onToDateChange(date)}
                />
            </Grid>

        
        </Grid>
        {/* </Card> */}
        {showProgress?(<Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box> ):<></>}
                         
        <Grid container spacing={2}  sx={{ mb: 5, mt:5 }} rowSpacing={2}>
             
            <Grid  item xs={12} sm={6} md={6}>
                  {isSync?   <Button variant="outlined" disabled color="error"   loading={true}  onClick={()=>syncToErp()} startIcon={<Iconify icon="gg:sync" />}>
                      Đồng Bộ Đơn 
                    </Button>:
                      <Button variant="outlined"  color="error"   onClick={()=>syncToErp()} startIcon={<Iconify icon="gg:sync" />}>
                      Đồng Bộ Đơn 
                    </Button>}
                    {showProgress?
                    <Button variant="outlined" disabled loading={true}  color="primary"  sx={{marginLeft: 2}}
                      onClick={()=>fetchOrders()} startIcon={<Iconify icon="gg:arrow-down-o" />}>
                      Cập Nhật Đơn 
                    </Button>:
                    <Button variant="outlined"  color="primary"  sx={{marginLeft: 2}}
                      onClick={()=>fetchOrders()} startIcon={<Iconify icon="gg:arrow-down-o" />}>
                      Cập Nhật Đơn 
                    </Button>}
                  </Grid>
              <Grid  item xs={12} sm={6} md={6}   direction="column"
                  align="left"
                  style={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination justifyContent="flex-end" count={Math.ceil(totalCount/8)}  onChange={handleChange}  variant="outlined" color="secondary" />
              </Grid>
        </Grid>
        
        <ProductList products={orderList} onCancelCompleted={onCancelCompleted} /> 
        {/* <ProductCartWidget />  */}
        {/*  */}
        
        {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center"  sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
             <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort /> 
          </Stack>
        </Stack>  
        
      */}
      </Container>
          </LocalizationProvider>
    </>
  );
}
