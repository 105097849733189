import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '50%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'string' },
    yaxis: [
      {
          seriesName: "Tiền Hàng",
          min:0,
          max:100000000,
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: (y) => {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)}`+ " JPY";
                }
                return y;
              },
            },
          }
      },
      {
          seriesName: "Đơn Hàng",
          opposite: true,
          min: 0,
          max: 10000,
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: (y) => {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)}`+ " Đơn";
                }
                return y;
              },
            },
          }
      },
  ],
    // tooltip: {
    //   shared: true,
    //   intersect: false,
    //   y: {
    //     formatter: (y) => {
    //       if (typeof y !== 'undefined') {
    //         return `${y.toFixed(0)}`;
    //       }
    //       return y;
    //     },
    //   },
    // },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
