import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import axios, * as others from 'axios'
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------
import config from '../config';


export default function DashboardAppPage() {
  const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const API_HOST = config.API_URL;
  const theme = useTheme();
  const months =   ['January', 'February', 'March', 'April', 'May', 'June', 'July', "August","September", "October","November", "December"];
  const [totalOrders, setTotalOrders]= useState(0);
  const [totalAmounts, setTotalAmounts]= useState(0);
  
  const [totalCusOrders, setTotalCusOrders]= useState(0);
  const [totalCusAmounts, setTotalCusAmounts]= useState(0);

  const [currentMonthOrders, setCurrentMonthOrders]= useState(0);
  const [currentMonthAmount, setCurrentMonthAmount]= useState(0);
  const [monthlyOrders, setMonthlyOrders]= useState([]);
  const [monthlyAmount, setMonthlyAmount]= useState([]);
  const [currentMonth, setCurrentMonth] = useState((new Date()).getMonth()+1); 
  const [orderDeviation, setOrderDeviation] = useState(0);
  const [amountDeviation, setAmountDeviation] = useState(0);
  const [platformReport, setPlatformReport] = useState([
    {label:"Mercari", value:1},
    {label:"Yahoo", value:1}]);

  const getMonthlyReport = ()=>{
    const report_url = API_HOST+"/api/Report/Order-Report";
    axios
      .get(report_url)
      .then((res) => {
        var report = res.data;
        if(report!=null){
          setOrderDeviation(Math.ceil(((report.monthlyStatistic[currentMonth].totalOrder/report.monthlyStatistic[currentMonth-1].totalOrder)-1)*100));
          setAmountDeviation(Math.ceil(((report.monthlyStatistic[currentMonth].totalAmount/report.monthlyStatistic[currentMonth-1].totalAmount)-1)*100));
        }
      })
      .catch((error) => {
        console.error("Error", error);
    });
  }

  const getPlatformReport = ()=>{
    const report_url = API_HOST+"/api/Report/sale-report-by-platform";
    axios
      .get(report_url)
      .then((res) => {
        if(res!=null && res.data!=null){
            setPlatformReport([
              { label: "Mercari", value: res.data[0]},
              {label: "Yahoo", value: res.data[1]}
            ]);
        }
      })
      .catch((error) => {
        console.error("Error", error);
    });
  }

  const getOrderReport = ()=>{
    const report_url = API_HOST+"/api/Report/Yahoo-Sale-Report";
    axios
      .get(report_url)
      .then((res) => {
        var report = res.data;
        if(report!=null){
          var orders = 0;
          for(var i=0; i<report.monthlyOrders.length; i++){
            orders+=report.monthlyOrders[i];
          }
          setMonthlyOrders(report.monthlyOrders);
          setTotalOrders(orders);

          var amount = 0;
          for(var i=0; i<report.monthlyAmounts.length; i++){
            amount+=report.monthlyAmounts[i];
          }
          setMonthlyAmount(report.monthlyAmounts);
          setTotalOrders(orders);
          setTotalAmounts(amount);

          const d = new Date();
          setCurrentMonthOrders(report.monthlyOrders[d.getMonth()])
          setCurrentMonthAmount(report.monthlyAmounts[d.getMonth()])
        }
      })
      .catch((error) => {
        console.error("Error", error);
    });
  }

  const getCustomerReport = (customerCode)=>{
    const report_url = API_HOST+"/api/Report/Customer-Report?customerCode="+customerCode;
    axios
      .get(report_url)
      .then((res) => {
        var report = res.data;
        if(report!=null){
          setTotalCusOrders(report.monthlyOrders);
          setTotalCusAmounts(report.monthlyAmounts);
        }
      })
      .catch((error) => {
        console.error("Error", error);
    });
  }

  const [topCustomers, setTopCustomers]= useState(["Chọn khách hàng"]);
  // const [selectedCustomer, setSelectedCustomer]= useState(["Chọn khách hàng"]);

  const getTopCustomers = ()=>{
    const customer_url = API_HOST+"/api/ProdOrder/GetTopCustomers";
    axios
      .get(customer_url)
      .then((res) => {
        if(res!=null){
          //setTopCustomers(res.data);
          var acc = ["Chọn khách hàng"];
          for (let index = 0; index < res.data.length; index++) {
                  acc.push(res.data[index]);
          }
          setTopCustomers(acc);
          getCustomerReport(res.data[0]);
        }
      })
      .catch((error) => {
        console.error("Error", error);
    });
  }

  useEffect(() => {
    getOrderReport();
    getTopCustomers();
    getMonthlyReport();
    getPlatformReport();
  }, [])


  return (
    <>
      <Helmet>
        <title> SNAPBUY INSIGHT </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back 
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Tổng Đơn Yahoo"  total={totalOrders}  color="primary" icon={'ant-design:shopping-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Tổng Tiền Hàng Yahoo" currency="¥" total={totalAmounts} color="info" icon={'ant-design:money-collect-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={"Đơn Hàng Tháng " + currentMonth + " ("+(orderDeviation<0?"-":"+") +Math.abs(orderDeviation)+"%)"} total={currentMonthOrders} color="warning" icon={'ant-design:shopping-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={"Tiền Hàng Tháng " + currentMonth + " ("+(amountDeviation<0?"-":"+")+Math.abs(amountDeviation)+"%)"}  currency="¥"  total={currentMonthAmount} color="error" icon={'ant-design:money-collect-outlined'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Biểu đồ đơn hàng Yahoo"
              subheader={ "("+Math.ceil(100*((monthlyAmount[currentMonth-1]/monthlyAmount[currentMonth-2])-1))+"%) so với tháng " + (currentMonth-1)}
              chartLabels={[
                'Tháng 1',
                'Tháng 2',
                'Tháng 3',
                'Tháng 4',
                'Tháng 5',
                'Tháng 6',
                'Tháng 7',
                'Tháng 8',
                'Tháng 9',
                'Tháng 10',
                'Tháng 11',
                'Tháng 12',
              ]}
              chartData={[
                {
                  name: 'Tiền Hàng',
                  type: 'column',
                  fill: 'solid',
                  data: monthlyAmount,
                  unit: "¥"
                },
                // {
                //   name: 'Team B',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                {
                  name: 'Đơn Hàng',
                  type: 'line',
                  fill: 'solid',
                  data: monthlyOrders,
                  unit: "Đơn"
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Cơ cấu doanh thu"
              chartData={platformReport}
              chartColors={[
                // theme.palette.primary.main,
                // theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
