import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import axios, * as others from 'axios';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Alert,
  Snackbar
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import config from '../config';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'index', label: '#', alignRight: false },
  { id: 'name', label: 'Tài Khoản', alignRight: false },
  { id: 'totalOrder', label: 'Tổng Đơn Hàng', alignRight: false },
  { id: 'lastSync', label: 'Đơn Hàng Gần Nhất', alignRight: false },
  { id: 'modifiedAt', label: 'Cookie', alignRight: false },
  { id: '', label:"Trạng Thái Đơn" , alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function YahooUserPage() {

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const API_HOST = config.API_URL;
  const [accounts, setAccounts] = useState([]);
  const [isSync, setIsSync] = useState(false);
  const [message, setMessage] = useState ("");

  // const { register, handleSubmit, errors, reset } = useForm({defaultValues: []});
  
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [emptyRows, setEmptyRows] = useState(true);
  const [isNotFound, setIsNotFound] = useState(true);

  useEffect(() => {
    const url = API_HOST+"/api/YahooAccount";
    axios
      .get(url)
      .then((res) => {
        // setFilteredUsers(applySortFilter(res.data, getComparator(order, orderBy), filterName));
        var users =res.data;
        for(var i=0; i<users.length; i++){
          users[i]["index"]=i+1;
        }
        setFilteredUsers(users);
        setEmptyRows( page > 0 ? Math.max(0, (1 + page) * rowsPerPage - res.data.length) : 0);
        setIsNotFound(!res.data.length && !!filterName);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }, []); 

  const [visible, setVisible] = useState(false);
  const [addModelVisible, setAddModelVisible] = useState(false);
  const account = {
    AccountName: "",
    AuctionCookie: "",
    ContactCookie: "",
    BuyCookie: "",
    LastSyncAt: null,
    CreatedDate: null,
    CreatedBy: "",
    ModifiedDate: null,
    ModifiedBy: ""
  };

  const addAccount = (e) => {
    const accout_url = API_HOST+"/api/YahooAccount";
    axios({
      method: 'post',
      url: accout_url,
      headers: {}, 
      data: account
    }).then(function (response) {
      setMessage("Add new account successfully!");
      setVisible(true);
    //   reset();
      axios
      .get(accout_url)
      .then((res) => {
        setAccounts(res.data);
        console.debug(res.data);
        setAddModelVisible(false);
        account.AccountName="";
        account.AuctionCookie="";
        account.ContactCookie="";
      })
      .catch((err) => {
        console.error("Error", err);
        setAddModelVisible(false);
        account.AccountName="";
        account.AuctionCookie="";
        account.ContactCookie="";
      });
    }).catch((error) => {
      console.error("Error", error);
      setAddModelVisible(false);
      account.AccountName="";
      account.AuctionCookie="";
      account.ContactCookie="";
    });
  }

 const [syncList, setSyncList] = useState([]);
 const [show, setShow] = useState(false);

 const sync=()=>{
  setSyncList([]);
  setIsSync(true);
    filteredUsers.forEach((account, index) => {
      syncOrder(account, index);
    });
    //setIsSync(false);
 }

  const syncOrder=(item, index)=>{
    var list = syncList;
    const url = API_HOST +"/api/Orders/Sync";
    const accout_url = API_HOST+ "/api/YahooAccount";

    axios({
      method: 'post',
      url: url,
      headers: {}, 
      data: item
    }).then(function (response) {
      list.push(item.accountName);
      setSyncList(list);
      if(syncList.length==filteredUsers.length){
         setIsSync(false);
      }
      setMessage("Sync order for account "+item.accountName+" completed!");
      setShow(true);
      axios
      .get(accout_url)
      .then((res) => {
        setAccounts(res.data);
        console.debug(res.data);

      })
      .catch((err) => {

        console.error("Error", err);
      });
    }).catch((error) => {
      if(syncList.length==filteredUsers.length){
        setIsSync(false);
       }
      list.push(item.accountName);
      setSyncList(list);
      console.error("Error", error);
    });
  }

  const onAccountNameChange = (e)=>{
    account.AccountName=e.target.value;
  }

  const onAuctionCookieChange = (e)=>{
    account.AuctionCookie=e.target.value;
  }
  
  const onContactCookieChange = (e)=>{
    account.ContactCookie=e.target.value;
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

 

  return (
    <>
      <Helmet>
        <title> Tài Khoản | Yahoo </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Button variant="contained" justifyContent="space-between"  startIcon={<Iconify icon="eva:plus-fill" />}>
            Thêm Tài Khoản
          </Button>
          {isSync?   <Button variant="outlined" disabled color="error"   loading={true}  onClick={()=>sync()} startIcon={<Iconify icon="gg:sync" />}>
            Đồng Bộ Đơn Hàng
          </Button>:
            <Button variant="outlined"  color="error"   onClick={()=>sync()} startIcon={<Iconify icon="gg:sync" />}>
            Đồng Bộ Đơn Hàng
          </Button>}
        
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers!=null?filteredUsers.length:0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, accountName, role, status, company, avatarUrl, isVerified } = row;
                    const selectedUser = selected.indexOf(accountName) !== -1;

                    return ( */}
                      {filteredUsers.map((item, index) => (
                      <TableRow hover key={item.id} tabIndex={-1} role="checkbox" selected={item}>
                        <TableCell align="center">
                          {/* <Checkbox checked={item} onChange={(event) => handleClick(event, item.accountName)} /> */}
                          <Typography variant="subtitle2" noWrap>
                              {item.index}
                            </Typography>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={item.accountName} src={item.avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {item.accountName}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{item.totalOrder}</TableCell>

                        <TableCell align="left">{item.lastSyncAt}</TableCell>

                        <TableCell align="left">{item.modifiedDate==null || (new Date(item.modifiedDate)).getDate()==(new Date()).getDate()? 
                        <CheckCircleOutlineOutlinedIcon color="success"/>:  <CancelOutlinedIcon color="error"/>}</TableCell>

                        <TableCell align="left">
                           <Label color={syncList.indexOf(item.accountName)>=0? 'success':'error'}>{syncList.indexOf(item.accountName)>=0?"Đồng bộ":"Chưa đồng bộ"}</Label>
                        </TableCell>

                        {/* <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  {/* //   );
                  // })} */}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Snackbar open={show} autoHideDuration={6000} onClose={() => setShow(false)}>
        <Alert onClose={() => setShow(false)} severity="success" sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
    </>
  );
}
