import { Helmet } from 'react-helmet-async';
// @mui
// import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { Card, Chip, Grid, Stack, Container, TextField, Typography, Button, Dialog, Tooltip, IconButton, DialogActions, CircularProgress } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { styled } from '@mui/material/styles';
import Image from '../components/Image';
import axios from 'axios';
import config from '../config';
import { useState, useEffect} from 'react';
import {parse, stringify, toJSON, fromJSON} from 'flatted';
import Iconify from '../components/iconify';
import useToggle from '../hooks/useToggle';
import { fShortenNumber, fCurrency, fData } from '../utils/formatNumber';
import {fDate} from '../utils/formatTime';
import Divider from '@mui/material/Divider';
import { useReactToPrint } from 'react-to-print';
import React, { useRef } from 'react';

// import {
//   FormProvider,
//   RHFSwitch,
//   RHFSelect,
//   RHFEditor,
//   RHFTextField,
//   RHFRadioGroup,
//   RHFUploadMultiFile,
// } from '../components/hook-form';
// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));


export default function InvoicePage() {

  const [order, setOrder] = useState({});
  const [weight, setWeight] = useState(300);
  const [price, setPrice] = useState(0);
  const [rate, setRate] = useState(170);
  const [serviceFee, setServiceFee] = useState(2);
  const [paymentFee, setPaymentFee] = useState(100);
  const [jpShipFee, setJpShipFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [invoice, setInvoice] = useState({});
  const [jpToVnShipFee, setJpToVnShipFee] = useState(175000);
  const [otherFee, setOtherFee] = useState(0);
  const [note, setNote] = useState('');
  const fetchOrder = (e)=>{
    const url =  config.API_URL+"/api/orders/fetchOrder";
    axios.post(url,
      {
        'orderUrl': e.target.value
      },
      {
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        }
      }
    ).then(function (response) {
          setOrder(response.data["order"]);
          if(response.data["order"]!=null){
            setPrice(response.data["order"]["productPrice"])
            if(response.data["order"]["sellerWeight"]!=null){
              setWeight(response.data["order"]["sellerWeight"]);
            }
          }
          
    }).catch((error) => {
        console.error("Error", error);
    });
  }

  const onWeightChange = (e)=>{
    setWeight(e.target.value);
  }

  const onPriceChange = (e)=>{
    setPrice(e.target.value);
  }

  const onRateChange = (e)=>{
    setRate(e.target.value);
  }

  const onPaymentFeeChange = (e)=>{
    setPaymentFee(e.target.value);
  }

  const onJpShipFeeChange = (e)=>{
    setJpShipFee(e.target.value);
  }

  const onServiceFeeChange = (e)=>{
    setServiceFee(e.target.value);
  }

  const onJpToVnShipFee=(e)=>{
    setJpToVnShipFee(e.target.value);
  }

  const onOtherFeeChange=(e)=>{
    setOtherFee(e.target.value);
  }
  
  const onNoteChange=(e)=>{
    setNote(e.target.value);
  }
  
  
  const { toggle: open, onOpen, onClose } = useToggle();
  const currDate = new Date();


  const contentToPrint = useRef();
  const handlePrint = useReactToPrint({
      pageStyle: `@media print {
        @page {
          size: B5;
          margin: 25px;
        }
      }`,
    documentTitle: "Invoice",
    content: ()=> contentToPrint.current,
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  return (
    <>
      <Helmet>
        <title> SnapBuy Insight | Hóa Đơn</title>
      </Helmet>

      <Container>
         <Grid container>
                <Grid item xs={12} md={6} lg={5}  >
                    <Card sx={{ p: 3 }}>
                    <Stack spacing={3} sx={{mb:3}}>
                      <TextField id="productUrl"  label="Link Sản Phẩm" variant="outlined" onChange={fetchOrder} />
                    </Stack>
                    <Grid sx={{mb:3}} spacing={3}  container>
                        <Grid item xs={12}  md={6} >
                         <TextField id="productPrice"   value={price} label="Giá Sản Phẩm (JPY)" variant="outlined" onChange={onPriceChange} />
                        </Grid>
                        <Grid item xs={12}  md={6} >
                        <TextField id="weight"     value={weight} label="Cân Nặng (gram)" variant="outlined" onChange={onWeightChange} />
                        </Grid>
                    </Grid>
                    <Grid sx={{mb:3}} spacing={3}  container>
                        <Grid item xs={12}  md={6} >
                        <TextField id="rate" value={rate} label="Tỷ Giá JPY/VND" variant="outlined" onChange={onRateChange} />
                        </Grid>
                        <Grid item xs={12}  md={6} >
                        <TextField id="jpToVnShipFee"     value={jpToVnShipFee} label="Phí Ship Về VN (VNĐ)" variant="outlined" onChange={onJpToVnShipFee} />
                        </Grid>
                    </Grid>
                    <Grid sx={{mb:3}} spacing={3}  container>
                        <Grid item xs={12}  md={6} >
                        <TextField id="paymentFee"  value={paymentFee} label="Phí Thanh Toán (JPY)" variant="outlined" onChange={onPaymentFeeChange} />
                        </Grid>
                        <Grid item xs={12}  md={6} >
                        <TextField id="serviceFee"   value={serviceFee} label="Phí Mua Hộ (%)" variant="outlined" onChange={onServiceFeeChange} />
                        </Grid>
                    </Grid>
                    <Grid sx={{mb:3}} spacing={3}  container>
                        <Grid item xs={12}  md={6} >
                      <TextField id="jpShipFee"  value={jpShipFee} label="Phí Ship Nội Địa Nhật Bản (JPY)" variant="outlined" onChange={onJpShipFeeChange} />
                        </Grid>
                        <Grid item xs={12}  md={6} >
                      <TextField id="otherFee"  value={jpShipFee} label="Phí Khác (VNĐ)" variant="outlined" onChange={onOtherFeeChange} />
                        </Grid>
                    </Grid>
                
                    <Stack spacing={3} sx={{mb:3}}>
                            <TextField
                              id="outlined-multiline-static"
                              label="Ghi Chú"
                              value={note}
                              onChange={onNoteChange}
                              multiline
                              rows={4}
                              defaultValue=""
                            />
                    </Stack>

                   
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={7} >
                  <Card sx={{ p: 3, ml:3 }}>
                  <Stack
                    spacing={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                    alignItems={{ sm: 'center' }}
                    sx={{ mb: 5 }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Print">
                        <IconButton onClick={handlePrint}>
                          <Iconify icon={'eva:printer-fill'} />
                        </IconButton>
                      </Tooltip>
                    </Stack> 
                  </Stack>
                  <Stack ref={contentToPrint}>
                    <Grid container >
                      <Grid item xs={12} sm={4}>
                      <Image disabledEffect  alt={'Image'} src={order.imageUrl?order.imageUrl:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIvDZdFJUGi9HcQDsZFo_hLp6c0xhlZmEUzQ&s'} sx={{ borderRadius: 1.5, width: 150, height: 150, mr: 2 }} />

                      </Grid>
                      <Grid item xs={12} sm={8}>
                          <Typography variant="subtitle2">
                              {order.productName}
                          </Typography>
                          <Grid container>
                              <Grid item xs={12} sm={4} sx={{ mb: 5, mt:5 }}>
                                <Typography paragraph variant="overline" >
                                  Giá SP
                                </Typography>
                                <Typography variant="body2">{fCurrency(price*rate, 'đ')}  VNĐ</Typography>
                              </Grid>
                              <Grid item xs={12} sm={4} sx={{ mb: 5, mt:5 }}>
                                <Typography paragraph variant="overline" >
                                  Cân Nặng
                                </Typography>
                                <Typography variant="body2">{fData(weight/1000)} Kg</Typography>
                              </Grid>
                              <Grid item xs={12} sm={4} sx={{ mb: 5, mt:5 }}>
                                <Typography paragraph variant="overline" >
                                  Ngày Lập 
                                </Typography>
                                <Typography variant="body2">{fDate(currDate,"dd/MM/yyyy")}</Typography>
                             
                              </Grid>
                          </Grid>
                       </Grid>
                    </Grid>
                    <Divider sx={{mb:5}}>HÓA ĐƠN</Divider>
                    <Grid container sx={{mb:3}}>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                                <Typography paragraph variant="body2" >
                                  Tiền Hàng
                                </Typography>  
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                          <Stack direction="row" justifyContent="flex-end">
                              <Typography variant="body2">{fCurrency(price*rate, 'đ')}  VNĐ</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                          
                                <Typography paragraph variant="body2" >
                                  Phí Ship Nội Địa Nhật Bản
                                </Typography>  
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                              <Stack direction="row" justifyContent="flex-end">
                                <Typography variant="body2">{jpShipFee==0?"0":fCurrency(jpShipFee*rate, 'đ')}  VNĐ</Typography>
                              </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                                <Typography paragraph variant="body2" >
                                  Phí Ship Về Việt Nam
                                </Typography>  
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                          <Stack direction="row" justifyContent="flex-end">
                               <Typography variant="body2">{fCurrency(jpToVnShipFee*weight/1000, 'đ')}  VNĐ</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                                <Typography paragraph variant="body2" >
                                  Phí Thanh Toán
                                </Typography>  
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                        <Stack direction="row" justifyContent="flex-end">
                               <Typography variant="body2">{paymentFee==0?"0":fCurrency(paymentFee*rate, 'đ')}  VNĐ</Typography>
                               </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                                <Typography paragraph variant="body2" >
                                  Phí Dịch Vụ ({serviceFee}%)
                                </Typography>  
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                                <Stack direction="row" justifyContent="flex-end">
                                  <Typography variant="body2">{fCurrency(price*rate*serviceFee/100, 'đ')}  VNĐ</Typography>
                                </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                                <Typography paragraph variant="body2" >
                                  Phụ Phí Khác
                                </Typography>  
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                             <Stack direction="row" justifyContent="flex-end">
                                  <Typography variant="body2">{otherFee==0?"0":fCurrency(otherFee, 'đ')}  VNĐ</Typography>
                                </Stack>
                        </Grid>
                    </Grid>
                    <Divider sx={{mb:3}}></Divider>
                    <Grid container sx={{mb:3}}>
                          <Grid item xs={12} sm={6} sx={{mb:3}}>
                                <Typography paragraph variant="h6" >
                                  Tổng Tiền Tạm Tính
                                </Typography>  
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:3}}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Typography paragraph variant="h6" color="#52BE80">
                                  {fCurrency(price*rate+jpShipFee*rate+weight*jpToVnShipFee/1000+paymentFee*rate+serviceFee*price*rate/100,"đ")} VNĐ
                                </Typography>  
                              </Stack>
                        </Grid>
                        </Grid>

                        <Typography paragraph variant="body2" color="red">
                                <i>{note}</i>
                        </Typography>  
                    </Stack>
                  </Card>
                </Grid>
         </Grid>
      </Container>
    </>
  );
}
