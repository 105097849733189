import React, { useState} from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, Button, Snackbar,Alert } from '@mui/material';
// import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import dayjs from 'dayjs';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import axios, * as others from 'axios'
import Iconify from 'src/components/iconify/Iconify';
import { Modal as BaseModal } from '@mui/base/Modal';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import config from "../../../config";
// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, onCancelCompleted }) {
  const { productName, imageUrl, productPrice, productUrl, sellerName, billCode, productId, paymentDate, shippingStatus  } = product;
  const API_HOST = config.API_URL;
  const [message, setMessage] = useState ("");
  const [show, setShow] = useState(false);


  const cancelOrder = ()=>{
    const cancel_url = API_HOST+ "/api/orders/cancel";

    axios({
      method: 'post',
      url: cancel_url,
      headers: {}, 
      data: product
    }).then(function (response) {
      setMessage("Cancel order successfully!");
      setShow(true);
      setOpen(false);
      onCancelCompleted();

    }).catch((error) => {
      setOpen(false);
      setMessage("Cancel order failed!");
      setShow(true);
    }); 
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
      <div
        className={clsx({ 'base-Backdrop-open': open }, className)}
        ref={ref}
        {...other}
      />
    );
  });
  
  Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
  };
  
  const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  
  const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
  `;
  
  const ModalContent = styled('div')(
    ({ theme }) => css`
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      box-shadow: 0 4px 12px
        ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
      padding: 24px;
      color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
  
      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }
  
      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
        margin-bottom: 4px;
      }
    `,
  );
  
  return (
    <div>
          <Card>
          <Box sx={{ pt: '100%', position: 'relative' }}>
          paymentDate
              <Label
                variant="filled"
                // color={(status === 'sale' && 'error') || 'info'}
                color={'info'}
                sx={{
                  zIndex: 9,
                  top: 16,
                  right: 16,
                  position: 'absolute',
                  textTransform: 'uppercase',
                }}
              >
                {sellerName}
              </Label>
              <Label
                variant="filled"
                color={((paymentDate==null||paymentDate==undefined) && 'error') || 'success'}
                sx={{
                  zIndex: 9,
                  top: 16,
                  left: 16,
                  position: 'absolute',
                  textTransform: 'uppercase',
                }}
              >
                {(paymentDate==null||paymentDate==undefined)? 'New': 'Paid'}
              </Label>
            {billCode!=null?(<Label
                variant="filled"
                // color={(status === 'sale' && 'error') || 'info'}
                color={'error'}
                sx={{
                  zIndex: 9,
                  bottom: 16,
                  right: 16,
                  position: 'absolute',
                  textTransform: 'uppercase',
                }}
              >
                {billCode}          
              </Label>):<></>}
              
            <StyledProductImg alt={productName} src={imageUrl} />
          </Box>

          <Stack spacing={2} sx={{ p: 3 }}>
            <Link href={productUrl} color="inherit" underline="hover" >
              <Typography variant="subtitle2" noWrap>
                {productName}
              </Typography>
            </Link>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {/* <ColorPreview colors={colors} /> */}
              <Typography
                  component="span"
                  variant="body1"
                  sx={{
                  }}
                >
                  {dayjs(product.sucessfulAuctionDate).format('DD/MM/YYYY HH:mm')}
                </Typography >
              <Typography variant="subtitle1">
                &nbsp;
                ¥{fCurrency(productPrice)}
              </Typography>
            </Stack>
          </Stack>
          <CardActions disableSpacing>
            <IconButton aria-label="Cancel Order"  sx={{ color: 'error.main' }} onClick={()=>handleOpen()}>
                        <Iconify icon={'eva:trash-2-outline'}  />
            </IconButton> 
            {shippingStatus==1?(<Iconify icon={'eva:car-fill'} color={'success'}/>):<></>} 
          </CardActions>
        </Card>

          <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={handleClose}
          slots={{ backdrop: StyledBackdrop }}
          >
          <ModalContent sx={{ width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Hủy Đơn
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
               Bạn có thực sự muốn hủy đơn này?
              </Typography>
              <Stack direction="row" justifyContent="flex-end" sx={{mt:10}} spacing={2}>
              <Button variant="outlined" startIcon={<DeleteIcon />} onClick={cancelOrder}>
                Đồng Ý
              </Button>
              <Button variant="contained" endIcon={<UndoIcon />} onClick={handleClose}>
                Không
              </Button>
            </Stack>
          </ModalContent>
          </Modal>

          <Snackbar open={show} autoHideDuration={6000} onClose={() => setShow(false)}>
            <Alert onClose={() => setShow(false)} severity="success" sx={{ width: '100%' }}>
            {message}
            </Alert>
          </Snackbar>
    </div>
    
  );
}
